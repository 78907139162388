<template>
  <div>
    <div class="project-name">
      <label for="projectName">Project Name:</label>
      <input type="text" id="projectName" v-model="projectName" placeholder="Enter project name" />
    </div>

    <!-- Dropzone for images -->
    <div
      class="dropzone"
      @dragover.prevent
      @dragenter.prevent
      @dragleave="dragLeave"
      @drop.prevent="handleDrop"
    >
      <p v-if="!files.length">Drag & Drop your images here</p>
      <input type="file" multiple @change="handleFiles" ref="fileInput" style="display: none;" />
      <div class="preview" v-if="files.length">
        <div v-for="(file, index) in files" :key="index" class="preview-image">
          <img :src="file.url" alt="image preview" />
        </div>
      </div>
    </div>

    <!-- Area for browsing CSV files -->
    <div class="csv-upload">
      <label for="csvFile">Browse CSV file:</label>
      <input type="file" id="csvFile" @change="handleCSVFile" accept=".csv" />
      <p v-if="csvFile">{{ csvFile.name }} selected</p>
    </div>

    <!-- Run button -->
    <button @click="confirmUpload" :disabled="!files.length || !projectName || !csvFile">Run</button>

    <!-- Progress bar -->
    <div v-if="uploading" class="progress-bar">
      <div class="progress" :style="{ width: uploadProgress + '%' }"></div>
      <p>{{ uploadProgress }}% uploaded</p>
    </div>

    <!-- Upload summary -->
    <div v-if="uploadSummary.total > 0">
      <p>{{ uploadSummary.success }} out of {{ uploadSummary.total }} images successfully uploaded</p>
    </div>
  </div>
</template>

<script>
import AWS from 'aws-sdk';
import awsConfig from '@/aws-config';
import Papa from 'papaparse';
import { v4 as uuidv4 } from 'uuid';
import { SESClient, SendEmailCommand } from '@aws-sdk/client-ses';


export default {
  data() {
    return {
      projectName: '',
      files: [],
      csvFile: null,
      csvData: [],
      uploading: false,
      uploadProgress: 0,
      uploadSummary: {
        success: 0,
        total: 0
      },
      projectGUID: uuidv4(), // Generate UUID for projectGUID
      username: 'user123' // Assuming you have the username from some authentication logic
    };
  },
  methods: {
    handleDrop(event) {
      const droppedFiles = Array.from(event.dataTransfer.files);
      this.processFiles(droppedFiles);
    },
    handleFiles(event) {
      const selectedFiles = Array.from(event.target.files);
      this.processFiles(selectedFiles);
    },
    handleCSVFile(event) {
      const file = event.target.files[0];
      this.csvFile = file;
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          this.csvData = results.data;
        },
      });
    },
    processFiles(fileList) {
      fileList.forEach(file => {
        const reader = new FileReader();
        reader.onload = e => {
          this.files.push({ file, url: e.target.result });
        };
        reader.readAsDataURL(file);
      });
    },
    async confirmUpload() {
      if (!this.projectName.trim()) {
        alert('Please enter a project name.');
        return;
      }

      try {
        const uuidExists = await this.checkIfUuidExists(this.projectGUID);
        if (uuidExists) {
          alert('A project with this UUID already exists. Please try again.');
          return;
        }

        this.uploading = true;
        this.uploadProgress = 0;
        this.uploadSummary = { success: 0, total: this.files.length };
        const s3 = new AWS.S3({
          region: awsConfig.region,
          accessKeyId: awsConfig.accessKeyId,
          secretAccessKey: awsConfig.secretAccessKey,
        });

        // Upload Images
        const imagePromises = this.files.map((file, index) => {
          const params = {
            Bucket: awsConfig.bucketName,
            Key: `${this.projectGUID}/${file.file.name}`,
            Body: file.file,
            ContentType: file.file.type,
          };

          return s3.upload(params).on('httpUploadProgress', progress => {
            const totalProgress = Math.round((progress.loaded / progress.total) * 100);
            this.uploadProgress = Math.round(((index + 1) / this.files.length) * totalProgress);
          }).promise().then(() => {
            this.uploadSummary.success += 1;
          });
        });

        await Promise.all(imagePromises);
        console.log('Image upload successful');

        // Upload CSV Data to DynamoDB
        await this.uploadCSVData();

        // Upload GuidToProjectName data to DynamoDB
        await this.uploadGuidToProjectName();

        // Send email notification
        await this.sendEmailNotification();

      } catch (error) {
        console.error('Upload failed:', error);
      } finally {
        this.uploading = false;
      }
    },
    async uploadCSVData() {
      const dynamoDB = new AWS.DynamoDB.DocumentClient({
        region: awsConfig.region,
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey,
      });

      const tableName = 'leica_image_data'; // Replace with your DynamoDB table name

      const uploadPromises = this.csvData.map((item, index) => {
        const params = {
          TableName: tableName,
          Item: {
            ProjectGUID: this.projectGUID, // Use generated UUID for projectGUID
            Id: index + 1, // Use CSV row number for Id
            SetupName: item['SetupName'],
            Position_X: parseFloat(item['Position(X)']),
            Position_Y: parseFloat(item['Position(y)']),
            Position_Z: parseFloat(item['Position(z)']),
            Orientation_W: parseFloat(item['Orientation(W)']),
            Orientation_X: parseFloat(item['Orientation(X)']),
            Orientation_Y: parseFloat(item['Orientation(Y)']),
            Orientation_Z: parseFloat(item['Orientation(Z)'])
          }
        };

        // Log the params to debug
        console.log('Uploading item:', params);

        return dynamoDB.put(params).promise().catch(error => {
          console.error(`Error uploading item with SetupName ${item.SetupName}:`, JSON.stringify(error, null, 2));
          throw error;
        });
      });

      try {
        await Promise.all(uploadPromises);
        console.log('CSV data upload successful');
      } catch (error) {
        console.error('CSV data upload failed:', error);
      }
    },
    async uploadGuidToProjectName() {
      const dynamoDB = new AWS.DynamoDB.DocumentClient({
        region: awsConfig.region,
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey,
      });

      const params = {
        TableName: 'GuidToProjectName', // Replace with your DynamoDB table name
        Item: {
          ProjectGUID: this.projectGUID, // Generated UUID
          Name: this.projectName,
          username: this.username // Assuming you have the username from some authentication logic
        }
      };

      // Log the params to debug
      console.log('Uploading GuidToProjectName item:', params);

      try {
        await dynamoDB.put(params).promise();
        console.log('GuidToProjectName data upload successful');
      } catch (error) {
        console.error('GuidToProjectName data upload failed:', JSON.stringify(error, null, 2));
      }
    },
    async checkIfUuidExists(uuid) {
      const dynamoDB = new AWS.DynamoDB.DocumentClient({
        region: awsConfig.region,
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey,
      });

      const params = {
        TableName: 'GuidToProjectName', // Replace with your DynamoDB table name
        KeyConditionExpression: 'ProjectGUID = :uuid',
        ExpressionAttributeValues: {
          ':uuid': uuid,
        },
      };

      try {
        const data = await dynamoDB.query(params).promise();
        return data.Items && data.Items.length > 0;
      } catch (error) {
        console.error('Error checking UUID existence:', JSON.stringify(error, null, 2));
        return false;
      }
    },
    async sendEmailNotification() {
      const sesClient = new SESClient({
        region: awsConfig.region,
        credentials: {
          accessKeyId: awsConfig.accessKeyId,
          secretAccessKey: awsConfig.secretAccessKey,
        }
      });

      const params = {
        Destination: {
          ToAddresses: ['amir@buildeye.ai'], // Change to the recipient's email address
        },
        Message: {
          Body: {
            Text: {
              Data: `The project with GUID: ${this.projectGUID} has been successfully uploaded to DynamoDB.`,
              Charset: 'UTF-8',
            },
          },
          Subject: {
            Data: `Project Upload Notification | ${this.projectGUID}`,
            Charset: 'UTF-8',
          },
        },
        Source: 'amir@buildeye.ai', // Change to your verified SES email address
      };

      try {
        const data = await sesClient.send(new SendEmailCommand(params));
        console.log('Email sent successfully:', data);
      } catch (error) {
        console.error('Error sending email:', error);
      }
    },

    dragLeave() {
      // Add custom behavior on drag leave if needed
    },
  },
};
</script>

<style>
.project-name {
  margin-bottom: 20px;
}

.project-name label {
  margin-right: 10px;
}

.dropzone {
  border: 2px dashed #ccc;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin: 20px 0;
  position: relative;
}

.dropzone input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.preview {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.preview-image {
  width: 100px;
  height: 100px;
  margin: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-image img {
  max-width: 100%;
  max-height: 100%;
}

.csv-upload {
  margin-top: 20px;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.progress-bar {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 20px;
}

.progress {
  height: 20px;
  background-color: #4caf50;
  width: 0;
  transition: width 0.3s;
}
</style>
